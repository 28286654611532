import { Routes } from '@angular/router';
import { CSettingsListService } from './pages/settings/services/settings.list.service';
import { CLangsListService } from './pages/langs/services/langs.list.service';
import { CDictsListService } from './pages/dicts/services/dicts.list.service';
import { CAdminsListService } from './pages/admins/services/admins.list.service';
import { CMailtemplatesListService } from './pages/mailtemplates/services/mailtemplates.list.service';
import { CBackupsListService } from './pages/backups/services/backups.list.service';
import { CFilesListService } from './pages/files/services/files.list.service';
import { CAuthGuard } from './common/services/guards/auth.guard';
import { CTimezonesListService } from './pages/timezones/services/timezones.list.service';
import { CLogsListService } from './pages/logs/services/logs.list.service';
import { CNationsListService } from './pages/nations/services/nations.list.service';
import { CPagesListService } from './pages/pages/services/pages.list.service';
import { CArticlesListService } from './pages/articles/services/articles.list.service';
import { CGalsListService } from './pages/gals/services/gals.list.service';
import { CUsersListService } from './pages/users/services/users.list.service';

export const routes: Routes = [
    {
        path: "",
        loadComponent: () => import("./pages/home/home.page").then(mod => mod.CHomePage),
        canActivate: [CAuthGuard],
    },
    {
        path: "auth",
        children: [
            {path: "login", loadComponent: () => import("./pages/auth/login/login.page").then(mod => mod.CLoginPage)},
            {path: "logout", loadComponent: () => import("./pages/auth/logout/logout.page").then(mod => mod.CLogoutPage)},
            {path: "recover", loadComponent: () => import("./pages/auth/recover/recover.page").then(mod => mod.CRecoverPage)},
            {path: "oauth/:token", loadComponent: () => import("./pages/auth/oauth/oauth.page").then(mod => mod.COAuthPage)},
        ],
    },
    {
        path: "settings",
        canActivate: [CAuthGuard],
        providers: [CSettingsListService],
        children: [
            {path: "", loadComponent: () => import("./pages/settings/settings.page").then(mod => mod.CSettingsPage), data: {use: "settings"}},
            {path: ":action", loadComponent: () => import("./pages/settings/settings.page").then(mod => mod.CSettingsPage),  data: {use: "settings"}},
            {path: ":action/:id", loadComponent: () => import("./pages/settings/settings.page").then(mod => mod.CSettingsPage), data: {use: "settings"}},
        ],
    },
    {
        path: "admins",
        canActivate: [CAuthGuard],
        providers: [CAdminsListService],
        children: [
            {path: "", loadComponent: () => import("./pages/admins/admins.page").then(mod => mod.CAdminsPage), data: {use: "admins"}},
            {path: ":action", loadComponent: () => import("./pages/admins/admins.page").then(mod => mod.CAdminsPage),  data: {use: "admins"}},
            {path: ":action/:id", loadComponent: () => import("./pages/admins/admins.page").then(mod => mod.CAdminsPage), data: {use: "admins"}},
        ],
    },
    {
        path: "localization/langs",
        canActivate: [CAuthGuard],
        providers: [CLangsListService],
        children: [
            {path: "", loadComponent: () => import("./pages/langs/langs.page").then(mod => mod.CLangsPage), data: {use: "langs"}},
            {path: ":action", loadComponent: () => import("./pages/langs/langs.page").then(mod => mod.CLangsPage),  data: {use: "langs"}},
            {path: ":action/:id", loadComponent: () => import("./pages/langs/langs.page").then(mod => mod.CLangsPage), data: {use: "langs"}},
        ],
    },
    {
        path: "localization/dicts",
        canActivate: [CAuthGuard],
        providers: [CDictsListService],
        children: [
            {path: "", loadComponent: () => import("./pages/dicts/dicts.page").then(mod => mod.CDictsPage), data: {use: "dicts"}},
            {path: ":action", loadComponent: () => import("./pages/dicts/dicts.page").then(mod => mod.CDictsPage),  data: {use: "dicts"}},
            {path: ":action/:id", loadComponent: () => import("./pages/dicts/dicts.page").then(mod => mod.CDictsPage), data: {use: "dicts"}},
        ],
    },
    {
        path: "localization/timezones",
        canActivate: [CAuthGuard],
        providers: [CTimezonesListService],
        children: [
            {path: "", loadComponent: () => import("./pages/timezones/timezones.page").then(mod => mod.CTimezonesPage), data: {use: "timezones"}},
            {path: ":action", loadComponent: () => import("./pages/timezones/timezones.page").then(mod => mod.CTimezonesPage),  data: {use: "timezones"}},
            {path: ":action/:id", loadComponent: () => import("./pages/timezones/timezones.page").then(mod => mod.CTimezonesPage), data: {use: "timezones"}},
        ],
    },
    {
        path: "localization/nations",
        canActivate: [CAuthGuard],
        providers: [CNationsListService],
        children: [
            {path: "", loadComponent: () => import("./pages/nations/nations.page").then(mod => mod.CNationsPage), data: {use: "nations"}},
            {path: ":action", loadComponent: () => import("./pages/nations/nations.page").then(mod => mod.CNationsPage),  data: {use: "nations"}},
            {path: ":action/:id", loadComponent: () => import("./pages/nations/nations.page").then(mod => mod.CNationsPage), data: {use: "nations"}},
        ],
    },
    {
        path: "files",
        canActivate: [CAuthGuard],
        providers: [CFilesListService],
        children: [
            {path: "", loadComponent: () => import("./pages/files/files.page").then(mod => mod.CFilesPage), data: {use: "files"}},
            {path: ":action", loadComponent: () => import("./pages/files/files.page").then(mod => mod.CFilesPage),  data: {use: "files"}},
            {path: ":action/:id", loadComponent: () => import("./pages/files/files.page").then(mod => mod.CFilesPage), data: {use: "files"}},
        ],
    },
    {
        path: "mailtemplates",
        canActivate: [CAuthGuard],
        providers: [CMailtemplatesListService],
        children: [
            {path: "", loadComponent: () => import("./pages/mailtemplates/mailtemplates.page").then(mod => mod.CMailtemplatesPage), data: {use: "mailtemplates"}},
            {path: ":action", loadComponent: () => import("./pages/mailtemplates/mailtemplates.page").then(mod => mod.CMailtemplatesPage),  data: {use: "mailtemplates"}},
            {path: ":action/:id", loadComponent: () => import("./pages/mailtemplates/mailtemplates.page").then(mod => mod.CMailtemplatesPage), data: {use: "mailtemplates"}},
        ],
    },
    {
        path: "backups",
        loadComponent: () => import("./pages/backups/backups.page").then(mod => mod.CBackupsPage),
        canActivate: [CAuthGuard],
        providers: [CBackupsListService],
    },
    {
        path: "logs",
        canActivate: [CAuthGuard],
        providers: [CLogsListService],
        children: [
            {path: "", loadComponent: () => import("./pages/logs/logs.page").then(mod => mod.CLogsPage), data: {use: "logs"}},
            {path: ":action", loadComponent: () => import("./pages/logs/logs.page").then(mod => mod.CLogsPage),  data: {use: "logs"}},
            {path: ":action/:id", loadComponent: () => import("./pages/logs/logs.page").then(mod => mod.CLogsPage), data: {use: "logs"}},
        ],
    },
    {
        path: "pages",
        canActivate: [CAuthGuard],
        providers: [CPagesListService],
        children: [
            {path: "", loadComponent: () => import("./pages/pages/pages.page").then(mod => mod.CPagesPage), data: {use: "pages"}},
            {path: ":action", loadComponent: () => import("./pages/pages/pages.page").then(mod => mod.CPagesPage),  data: {use: "pages"}},
            {path: ":action/:id", loadComponent: () => import("./pages/pages/pages.page").then(mod => mod.CPagesPage), data: {use: "pages"}},
        ],
    },
    {
        path: "articles",
        canActivate: [CAuthGuard],
        providers: [CArticlesListService],
        children: [
            {path: "", loadComponent: () => import("./pages/articles/articles.page").then(mod => mod.CArticlesPage), data: {use: "articles"}},
            {path: ":action", loadComponent: () => import("./pages/articles/articles.page").then(mod => mod.CArticlesPage),  data: {use: "articles"}},
            {path: ":action/:id", loadComponent: () => import("./pages/articles/articles.page").then(mod => mod.CArticlesPage), data: {use: "articles"}},
        ],
    },
    {
        path: "gals",
        canActivate: [CAuthGuard],
        providers: [CGalsListService],
        children: [
            {path: "", loadComponent: () => import("./pages/gals/gals.page").then(mod => mod.CGalsPage), data: {use: "gals"}},
            {path: ":action", loadComponent: () => import("./pages/gals/gals.page").then(mod => mod.CGalsPage),  data: {use: "gals"}},
            {path: ":action/:id", loadComponent: () => import("./pages/gals/gals.page").then(mod => mod.CGalsPage), data: {use: "gals"}},
        ],
    },
    {
        path: "users",
        canActivate: [CAuthGuard],
        providers: [CUsersListService],
        children: [
            {path: "", loadComponent: () => import("./pages/users/users.page").then(mod => mod.CUsersPage), data: {use: "users"}},
            {path: ":action", loadComponent: () => import("./pages/users/users.page").then(mod => mod.CUsersPage),  data: {use: "users"}},
            {path: ":action/:id", loadComponent: () => import("./pages/users/users.page").then(mod => mod.CUsersPage), data: {use: "users"}},
        ],
    },
];
