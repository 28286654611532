import { AfterViewInit, Component } from "@angular/core";
import { NavigationEnd, Router, RouterLink } from "@angular/router";
import { BehaviorSubject, filter } from "rxjs";
import { CAppService } from "src/app/common/services/app.service";
import { CActivatorablePanelComponent } from "../../panels/_activatorable.panel.component";
import { Timeout } from "src/app/common/decorators/timeout";
import { CAdmin } from "src/app/model/entities/admin";
import { CAuthService } from "src/app/common/services/auth.service";

@Component({
    selector: "the-sidebar",
    templateUrl: "sidebar.component.html",
    styleUrl: "sidebar.component.scss",
    standalone: true,
    imports: [RouterLink],
})
export class CSidebarComponent extends CActivatorablePanelComponent implements AfterViewInit {
    public subActive = {localization: false};

    constructor(
        protected override appService: CAppService,
        protected authService: CAuthService,
        protected router: Router,
    )
    {
        super(appService);
    }

    get activator(): BehaviorSubject<boolean> {return this.appService.sidebarActive;}
    get url(): string[] {return this.appService.url;}
    get admin(): CAdmin {return this.authService.admin;}

    ngAfterViewInit(): void {
        this.initSub();
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(() => {
                this.initSub();
                this.active && this.onClose();
            });
    }

    @Timeout(1)
    protected initSub(): void {
        for (let field in this.subActive) {
            this.subActive[field] = this.url[1] === field;
        }
	}

    protected toggleSub(name: string): void {
		this.subActive[name] = !this.subActive[name];
	}
}