<header>
    <div class="h-side">
        @if (authenticated) {<button class="roundbtn menu" [title]="appWords['header']?.['menu']?.[appLang.slug]" (click)="onActivateSidebar()"><span></span></button>}
    </div>
    <div class="h-side">
        <div class="h-saving" [class.active]="saving"><span></span></div>
        @if (admin) {
            <a class="h-admin" [class.disabled]="admin.group_id !== 1" [title]="appWords['header']?.['profile']?.[appLang.slug]" routerLink="/admins/edit/{{admin.id}}">
                @if (admin.img) {<img src="/static/image/admins/{{admin.img}}" alt="admin" loading="lazy">} 
                @else {<span>{{admin.name?.[0] || admin.email[0]}}</span>}   
            </a>
        }        
        <button class="roundbtn options" [title]="appWords['header']?.['options']?.[appLang.slug]" (click)="onActivateOptions()"><span></span></button>
        @if (authenticated) {<a class="roundbtn logout" routerLink="/auth/logout" [title]="appWords['header']?.['logout']?.[appLang.slug]"><span></span></a>}
    </div>
</header>