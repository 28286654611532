import {ActivatedRouteSnapshot, BaseRouteReuseStrategy} from '@angular/router';

export class CExtendedReuseStrategy extends BaseRouteReuseStrategy {
    public override shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {        
        // default angular behavior
        if (future.routeConfig === curr.routeConfig) {
            return true;
        }        
        
        // my addon for reuse marked components
        if (            
            future.routeConfig &&
            future.routeConfig.data && 
            future.routeConfig.data["use"] &&             
            curr.routeConfig && 
            curr.routeConfig.data && 
            curr.routeConfig.data["use"] &&             
            future.routeConfig.data["use"] === curr.routeConfig.data["use"]
        ) 
        {            
            return true;
        }            

        return false;
    }    
}