import { Component } from "@angular/core";
import { CActivatorablePanelComponent } from "../_activatorable.panel.component";
import { BehaviorSubject } from "rxjs";

@Component({
    selector: "panel-error",
    templateUrl: "error.panel.component.html",
    styleUrl: "error.panel.component.scss",
    standalone: true,
})
export class CErrorPanelComponent extends CActivatorablePanelComponent {
    get activator(): BehaviorSubject<boolean> {return this.appService.errorPanelActive;}
    get message(): string {return this.appService.errorMsg;}

    public onReload(): void {
        document.location = document.location;
    }
}
