<div class="panelside-ovl" [class.active]="active" (click)="onClose()"></div>
<form class="panelside w300c" [class.active]="active" (submit)="onApply()">
    <div class="p-head">
        <div class="p-title"><span class="p-icon options"></span><span class="p-name">{{appWords['options']?.['head']?.[appLang.slug]}}</span></div>
        <button type="button" class="roundbtn close" [title]="appWords['common']?.['close']?.[appLang.slug]" (click)="onClose()"><span></span></button>
    </div>
    <div class="p-main bscroll-v">
        <div class="gf">
            <div class="gf-row">
                <div class="gf-title">{{appWords['options']?.['lang']?.[appLang.slug]}}</div>
                <div class="gf-input">
                    <select-static [(value)]="appLangSlug" [items]="selectableAppLangs"></select-static>                     
                </div>
            </div>            
            <div class="gf-row">
                <div class="gf-title">{{appWords['options']?.['chunk_length']?.[appLang.slug]}}</div>
                <div class="gf-input">
                    <select-static 
                        [(value)]="chunkLength" 
                        [items]="[
                            {title: '10', value: 10},
                            {title: '25', value: 25},
                            {title: '50', value: 50},
                            {title: '100', value: 100},                            
                        ]"
                    ></select-static>                                     
                </div>
            </div>
        </div>
    </div>
    <div class="p-foot">
        <div class="pf-side"></div>
        <div class="pf-side">
            <button type="submit" class="btn"><span class="btn-icon apply"></span><span class="btn-txt">{{appWords['common']?.['apply']?.[appLang.slug]}}</span></button>
        </div>
    </div>
</form>
