<div class="snow"><img src="/assets/images/snow2.svg" alt="snow"></div>
<div class="wrap" [dir]="dir">
    <panel-error />
    @if (ready) {
        @if (authenticated) {<aside><the-sidebar /></aside>}
        <main id="win" class="bscroll-v">
            <the-header />
            <div class="content"><router-outlet /></div>
        </main>
        <panel-options />
    }
</div>
