import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { filter } from 'rxjs';
import { TDir } from './model/dir';
import { CAuthService } from './common/services/auth.service';
import { CSidebarComponent } from './common/components/structure/sidebar/sidebar.component';
import { CHeaderComponent } from './common/components/structure/header/header.component';
import { COptionsPanelComponent } from './common/components/panels/options/options.panel.component';
import { CErrorPanelComponent } from './common/components/panels/error/error.panel.component';
import { CAppService } from './common/services/app.service';
import { mscPause } from './common/functions/misc';
import { domGetElementById } from './common/functions/dom';

@Component({
    selector: 'app-root',
    templateUrl: "app.component.html",
    styleUrls: [
        "common/styles/var.scss",
        "common/styles/layout.scss",
        "common/styles/general.scss",
        "common/styles/helpers.scss",
        "common/styles/buttons.scss",
    ],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        RouterOutlet,
        CSidebarComponent,
        CHeaderComponent,
        COptionsPanelComponent,
        CErrorPanelComponent,
    ],
})
export class CAppComponent implements OnInit {
    private appServiceReady: boolean = false;

    constructor(
        private appService: CAppService,
        private authService: CAuthService,
        private router: Router,
    ) {}

    get dir(): TDir {return this.appService.appLang.value?.dir || "ltr";}
    get authenticated(): boolean {return this.authService.token.value !== null;}
    get ready(): boolean {return this.appServiceReady;}

    ///////////////
    // init
    ///////////////

    async ngOnInit(): Promise<void> {
        this.initAppService();
        this.initWin();
    }

    ////////////////
    // utils
    ////////////////

    private initAppService(): void {
        this.appService.init();
        this.appServiceReady = true;
    }

    private async initWin(): Promise<void> {
        this.appService.win = await domGetElementById("win");
        this.router.events
			.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe(() => this.appService.win.scrollTop && setTimeout(() => this.appService.win.scrollTo({top: 0}), 1));
        const splash = document.getElementById("splash");
        await mscPause(500);
        splash?.classList.add("transparent");
    }
}
