<div class="error-ovl" [class.active]="active" (click)="onClose()"></div>
<div class="error" [class.active]="active">
    <div class="e-head">
        <div class="e-icon"><span></span></div>
        <button type="button" class="roundbtn close" (click)="onClose()"><span></span></button>
    </div>
    <div class="e-main">{{message}}</div>
    <div class="e-foot">
        <button class="btn" (click)="onReload()">
            <span class="btn-icon reload"></span>
            <span class="btn-txt">Reload/Перезагрузить</span>
        </button>
    </div>
</div>