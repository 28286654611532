import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { RouteReuseStrategy, provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { CExtendedReuseStrategy } from './common/strategies/extended.reuse.strategy';
import { httpAuthInterceptor } from './common/services/net/http/http.auth.interceptor';

export const appConfig: ApplicationConfig = {
    providers: [
        provideZoneChangeDetection({ eventCoalescing: true }),
        provideRouter(routes),
        provideHttpClient(withInterceptors([
            httpAuthInterceptor,
        ])),
        {provide: RouteReuseStrategy, useClass: CExtendedReuseStrategy},
    ]
};
