import { Component, OnInit } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { CActivatorablePanelComponent } from "../_activatorable.panel.component";
import { FormsModule } from "@angular/forms";
import { ISelectable } from "src/app/model/selectable";
import { CStaticSelectComponent } from "../../inputs/selects/single/static/static.select.component";
import { IAppLang } from "src/app/model/localization/app.lang";

@Component({
    selector: "panel-options",
    templateUrl: "options.panel.component.html",
    styleUrls: [
        "../../../styles/panels-side.scss",
        "../../../styles/forms.scss",
    ],
    standalone: true,
    imports: [
        CStaticSelectComponent,
        FormsModule,
    ],
})
export class COptionsPanelComponent extends CActivatorablePanelComponent implements OnInit {
    // options
    public appLangSlug: string = null;
    public chunkLength: number = null;
    // helpers
    public selectableAppLangs: ISelectable<string>[] = [];

    get activator(): BehaviorSubject<boolean> {return this.appService.optionsActive;}
    get appLangs(): IAppLang[] {return this.appService.appLangs;}

    ////////////////
    // init
    ////////////////

    override ngOnInit(): void {
        super.ngOnInit();
        this.initBehavior();
        this.initHelpers();
    }

    protected initBehavior(): void {
        this.activator.subscribe(active => {
            if (active) {
                this.appLangSlug = this.appService.appLang.value.slug;
                this.chunkLength = this.appService.chunkLength.value;
            }
        });
    }

    protected initHelpers(): void {
        this.selectableAppLangs = this.appLangs.map(l => ({title: l.name, value: l.slug}));
    }

    /////////////////
    // events
    /////////////////

    public onApply(): void {
        this.appService.appLang.value.slug !== this.appLangSlug && this.appService.appLang.next(this.appLangs.find(l => l.slug === this.appLangSlug));
        this.appService.chunkLength.value !== this.chunkLength && this.appService.chunkLength.next(this.chunkLength);
        this.save();
        this.onClose();
    }

    ///////////////////
    // utils
    ///////////////////

    private save(): void {
        window.localStorage.setItem("options", JSON.stringify({
            appLangSlug: this.appLangSlug,
            chunkLength: this.chunkLength,
        }));
    }
}
