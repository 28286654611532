import { HttpEvent, HttpHandlerFn, HttpRequest, HttpResponse } from "@angular/common/http";
import { Observable, tap } from "rxjs";

export function httpAuthInterceptor(request: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>>  {
    return next(request).pipe(tap(event => {
        if (event instanceof HttpResponse && event.body["status_code"] === 403) {
            document.location = "/auth/logout";
        }
    }));
}
