import { Component } from "@angular/core";
import { RouterLink } from "@angular/router";
import { CAdmin } from "src/app/model/entities/admin";
import { CAppService } from "src/app/common/services/app.service";
import { CAuthService } from "src/app/common/services/auth.service";
import { CComponent } from "../../_component";

@Component({
    selector: "the-header",
    templateUrl: "header.component.html",
    styleUrl: "header.component.scss",
    standalone: true,
    imports: [RouterLink],
})
export class CHeaderComponent extends CComponent {
    constructor(
        protected override appService: CAppService,
        protected authService: CAuthService,
    )
    {
        super(appService);
    }

    get admin(): CAdmin {return this.authService.admin;}
    get authenticated(): boolean {return this.authService.token.value !== null;}
    get saving(): boolean {return this.appService.saving;}

    public onActivateSidebar(): void {
        this.appService.sidebarActive.next(true);
    }

    public onActivateOptions(): void {
        this.appService.optionsActive.next(true);
    }
}