<div class="sidebar-ovl" [class.active]="active" (click)="onClose()"></div>
<div class="sidebar bscroll-v" [class.active]="active">
    <div class="s-logo"><img src="/assets/images/logo_b9.svg" alt="logo"></div>
    <nav>
        @if (!admin) {<div class="loading"><span></span></div>}
        @else {
            <section>
                <div class="sn-head">
                    <div class="sn-head1">{{appWords['sidebar']?.['basic1']?.[appLang.slug]}}</div>
                    <div class="sn-head2">{{appWords['sidebar']?.['basic2']?.[appLang.slug]}}</div>
                </div>
                <div class="sn-items">
                    <a class="home" [class.active]="!url[1]" routerLink="/"><span>{{appWords['sidebar']?.['home']?.[appLang.slug]}}</span></a>
                    @if (admin.group_id === 1) {
                        <a class="settings" [class.active]="url[1] === 'settings'" routerLink="/settings"><span>{{appWords['sidebar']?.['settings']?.[appLang.slug]}}</span></a>
                        <a class="admins" [class.active]="url[1] === 'admins'" routerLink="/admins"><span>{{appWords['sidebar']?.['admins']?.[appLang.slug]}}</span></a>
                        <a class="localization expandable" [class.expanded]="subActive.localization" [class.active]="url[1] === 'localization'" (click)="toggleSub('localization')"><span>{{appWords['sidebar']?.['group_localization']?.[appLang.slug]}}</span></a>
                        <div class="sn-sub" [class.active-4]="subActive.localization">
                            <a [class.active]="url[2] === 'langs'" routerLink="/localization/langs"><span>{{appWords['sidebar']?.['langs']?.[appLang.slug]}}</span></a>
                            <a [class.active]="url[2] === 'dicts'" routerLink="/localization/dicts"><span>{{appWords['sidebar']?.['dicts']?.[appLang.slug]}}</span></a>
                            <a [class.active]="url[2] === 'timezones'" routerLink="/localization/timezones"><span>{{appWords['sidebar']?.['timezones']?.[appLang.slug]}}</span></a>
                            <a [class.active]="url[2] === 'nations'" routerLink="/localization/nations"><span>{{appWords['sidebar']?.['nations']?.[appLang.slug]}}</span></a>
                        </div>
                        <a class="files" [class.active]="url[1] === 'files'" routerLink="/files"><span>{{appWords['sidebar']?.['files']?.[appLang.slug]}}</span></a>
                        <a class="gals" [class.active]="url[1] === 'gals'" routerLink="/gals"><span>{{appWords['sidebar']?.['gals']?.[appLang.slug]}}</span></a>
                    }
                </div>
            </section>
            <section>
                <div class="sn-head">
                    <div class="sn-head1">{{appWords['sidebar']?.['main1']?.[appLang.slug]}}</div>
                    <div class="sn-head2">{{appWords['sidebar']?.['main2']?.[appLang.slug]}}</div>
                </div>
                <div class="sn-items">
                    <a class="pages" [class.active]="url[1] === 'pages'" routerLink="/pages"><span>{{appWords['sidebar']?.['pages']?.[appLang.slug]}}</span></a>
                    <a class="articles" [class.active]="url[1] === 'articles'" routerLink="/articles"><span>{{appWords['sidebar']?.['articles']?.[appLang.slug]}}</span></a>
                    <a class="users" [class.active]="url[1] === 'users'" routerLink="/users"><span>{{appWords['sidebar']?.['users']?.[appLang.slug]}}</span></a>
                </div>
            </section>
            @if (admin.group_id === 1) {
                <section>
                    <div class="sn-head">
                        <div class="sn-head1">{{appWords['sidebar']?.['utils1']?.[appLang.slug]}}</div>
                        <div class="sn-head2">{{appWords['sidebar']?.['utils2']?.[appLang.slug]}}</div>
                    </div>
                    <div class="sn-items">
                        <a class="mailtemplates" [class.active]="url[1] === 'mailtemplates'" routerLink="/mailtemplates"><span>{{appWords['sidebar']?.['mailtemplates']?.[appLang.slug]}}</span></a>
                        <a class="backups" [class.active]="url[1] === 'backups'" routerLink="/backups"><span>{{appWords['sidebar']?.['backups']?.[appLang.slug]}}</span></a>
                        <a class="logs" [class.active]="url[1] === 'logs'" routerLink="/logs"><span>{{appWords['sidebar']?.['logs']?.[appLang.slug]}}</span></a>
                    </div>
                </section>
            }
        }
    </nav>
</div>